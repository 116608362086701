.FeatureDataDisplayAndEdit {
  width: $full-width;

  .featureGrid {
    .featureDataHeader {
      padding-bottom: 2.5rem;
      padding-left: 0;
      margin-left: 2.4rem;
      .data {
        justify-content: center;
      }

      @include themed($themes) {
        border-bottom: 0.1rem solid t("text-color-tercery");
      }
    }

    .featureData {
      padding-bottom: 1.5rem;
      padding-left: 0;
      margin-left: 2.4rem;

      @include themed($themes) {
        border-bottom: 0.1rem solid t("text-color-tercery");
      }

      .rowNameData {
        display: flex;
        padding-top: 1.5rem;
        align-items: center;
      }

      .data {
        padding-top: 1.5rem;
        @include themed($themes) {
          color: t("text-color-tercery");
        }
        justify-content: center;
        align-items: center;

        .StyledButton {
          .MuiButtonBase-root {
            font-size: 1.2rem !important;
          }
        }

        .InputSwitch {
          width: unset;
        }
      }
    }

    .featureButtons {
      padding-left: 0;
      margin-left: 2.4rem;
      .button {
        padding-top: 1.5rem;
        justify-content: center;

        .StyledButton {
          .MuiButtonBase-root {
            font-size: 1.2rem !important;
          }
        }
      }
    }
  }
}
