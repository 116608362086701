.StyledChip {
  font-size: 1.2rem !important;
  min-width: 15rem;

  .MuiChip-label {
    @include themed($themes) {
      color: t("text-color-primary");
    }
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
