.GenericTable {
  // margin-top: 3rem;

  .MuiDataGrid-root {
    @include themed($themes) {
      background: t("component-background-color");
      color: t("text-color-primary");
      border-color: t("border-color");
    }
    font-size: 1.6rem !important;

    .MuiDataGrid-toolbarContainer {
      @include themed($themes) {
        border-color: t("border-color");
      }
      border-bottom: 0.1rem solid;
      .MuiButtonBase-root {
        font-size: 1.6rem !important;
        @include themed($themes) {
          color: t("text-color-primary");
        }
      }

      .MuiFormControl-root {
        .MuiInputBase-root {
          color: inherit;
          font-size: 1.6rem;

          &::before {
            border-color: inherit;
          }

          .MuiSvgIcon-root {
            color: inherit;
            font-size: 1.6rem;
          }

          .MuiInputAdornment-root {
            color: inherit;
          }

          .MuiOutlinedInput-notchedOutline {
            border-color: $primary;
          }
        }

        .MuiInput-root {
          &::after {
            border-color: $primary;
          }
        }

        .Mui-focused {
          .MuiOutlinedInput-notchedOutline {
            @include themed($themes) {
              border-color: t("text-color-primary");
            }
          }
        }
      }
    }

    .MuiDataGrid-main {
      .MuiDataGrid-topContainer {
        .MuiDataGrid-columnHeader {
          @include themed($themes) {
            background: t("component-background-color");
            color: t("text-color-primary");
            border-color: t("border-color");
          }
        }

        .header {
          @include themed($themes) {
            background: t("component-background-color");
            color: t("text-color-primary");
          }

          svg {
            @include themed($themes) {
              color: t("text-color-primary");
            }
            font-size: 1em !important;
          }
        }

        &::after {
          height: 0px;
        }
      }

      .MuiDataGrid-cell {
        @include themed($themes) {
          background: t("component-background-color");
          border-color: t("border-color");
        }

        svg {
          @include themed($themes) {
            color: t("text-color-primary");
          }
        }

        .clickableBox {
          cursor: pointer;
          // text-decoration: underline;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .MuiDataGrid-cell--editing {
        @include themed($themes) {
          background: t("component-background-color");
        }

        .InputText {
          input {
            padding: 1.35rem 1.4rem !important;
          }
        }
      }

      .MuiDataGrid-cell--flex {
        @include themed($themes) {
          background: t("component-background-color");
        }
      }
    }

    .MuiTablePagination-root {
      @include themed($themes) {
        color: t("text-color-primary");
      }
      font-size: 1.6rem !important;

      p {
        font-size: 1.6rem !important;
      }

      svg {
        @include themed($themes) {
          color: t("text-color-primary");
        }
        font-size: 1em !important;
      }
    }

    .MuiDataGrid-footerContainer {
      @include themed($themes) {
        border-color: t("border-color");
      }
    }

    .MuiDataGrid-filler {
      div {
        @include themed($themes) {
          border-color: t("border-color");
        }
      }
    }
  }
}
