.StyledPaper {
  @include themed($themes) {
    background: t("component-background-color");
    color: t("text-color-primary");
    border: t("paper-border");
  }
  padding: 1.5rem;
  box-shadow: none !important;
  border-radius: 0.6rem !important;
  flex: 1;
}
