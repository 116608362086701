.Overview {
  width: $full-width;
  margin-top: 5rem;

  .item {
    display: flex;
    flex-direction: column;
    height: $full-height;

    .StyledPaper {
      padding: 0;
      margin-top: 0;

      .header {
        padding: 2.5rem;
        display: flex;
        border-bottom: 0.1rem solid $primary;

        .titleAndIcon {
          display: flex;
          align-items: center;
          margin-bottom: 0;
          margin-right: 1rem;

          svg {
            font-size: 2rem;
          }
        }
      }
      .children {
        padding: 1rem 2.5rem;
        flex: 1;

        .child {
          padding: 0.5rem;
          display: flex;
          align-items: center;
          cursor: pointer;
          width: fit-content;

          svg {
            font-size: 1.5rem;
            margin-left: 1rem;
            color: $primary;
          }
        }
      }
    }
  }
}
