.InputSwitch {
  width: $full-width;

  .MuiFormControlLabel-root {
    width: $full-width;
    justify-content: space-between;
    margin-left: 0;

    .MuiSwitch-switchBase {
      &.Mui-checked {
        color: $primary;
      }

      & + .MuiSwitch-track {
        background-color: $primary;
      }
    }

    .MuiTypography-root {
      font-size: 1.6rem;
    }
  }
}
