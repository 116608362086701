.InputRadio {
  width: $full-width;
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  justify-content: space-between;

  .MuiFormLabel-root {
    @include themed($themes) {
      color: t("text-color-primary");
    }
    font-size: 1.6rem;
  }

  .MuiFormGroup-root {
    display: flex;
    flex-direction: row;
    flex-wrap: unset;
    width: $full-width;
    max-width: 40rem;

    .MuiFormControlLabel-root {
      @include themed($themes) {
        background-color: t("background-color") !important;
        // border: 0.1rem solid t("text-color-primary");
      }
      border: 0.1rem solid $primary;
      width: $full-width;
      font-size: 1.6rem;
      border-radius: $border-radius-s;
      padding: 1rem;
      max-height: 5.6rem;

      &.checked {
        @include themed($themes) {
          border-color: t("text-color-primary");
        }
        // border-color: $primary;
      }

      .MuiButtonBase-root {
        @include themed($themes) {
          color: t("text-color-primary");
        }

        &.Mui-checked {
          @include themed($themes) {
            color: t("text-color-primary");
          }
          // color: $primary;
        }
      }

      .MuiTypography-root {
        font-size: 1.6rem;
      }
    }

    .MuiFormControlLabel-root:last-child {
      margin-right: 0;
    }
  }
}
