.ActivateSims {
  .buttons {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: $full-width;
    padding-top: 3rem;

    .StyledButton {
      width: $full-width;
      max-width: 20rem !important;
      margin-left: 2rem;
    }

    .cancel {
      .MuiButton-root {
        background: red;
      }
    }
  }
}
