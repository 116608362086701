.ManageOrderB2B {
  .dataHeader {
    border-bottom: 0.1rem solid $primary;
    padding-bottom: 2rem;
  }

  .dataHeader:not(:first-child) {
    margin-top: 4rem;
  }

  .data {
    padding-top: 2rem;
    display: flex;
    flex-direction: row;
  }

  .dataGrid {
    padding-top: 2rem;
  }

  .StyledPaper {
    margin-top: 2rem;
    padding: 3rem;

    .buttons {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      width: $full-width;
      padding-top: 3rem;

      .StyledButton {
        max-width: 15rem;
        margin-left: 2rem;
      }

      .cancel {
        .MuiButton-root {
          background: red;
        }
      }
    }
  }
}
