.InputDate {
  width: $full-width;

  .MuiFormLabel-root {
    @include themed($themes) {
      color: t("text-color-primary");
    }
    font-size: 1.6rem;

    &.Mui-disabled {
      @include themed($themes) {
        color: t("disabled-color");
      }
    }
  }

  .MuiInputBase-root {
    @include themed($themes) {
      background-color: t("background-color") !important;
    }

    &.Mui-disabled {
      @include themed($themes) {
        border-color: t("disabled-color");
      }
      .MuiOutlinedInput-notchedOutline {
        @include themed($themes) {
          border-color: t("disabled-color") !important;
        }
      }
    }

    .MuiInputBase-input {
      @include themed($themes) {
        color: t("text-color-primary");
        -webkit-box-shadow: 0 0 0 3rem t("background-color") inset !important;
        -webkit-text-fill-color: t("text-color-primary");
      }
      // padding: 1.85rem 1.6rem;
      font-size: 1.6rem;
    }

    svg {
      @include themed($themes) {
        color: t("text-color-primary");
      }
      font-size: 2rem;
    }
  }

  .MuiOutlinedInput-root {
    fieldset {
      border-color: $primary !important;
    }

    &:hover fieldset {
      @include themed($themes) {
        border-color: t("text-color-primary") !important;
      }
    }

    &.Mui-focused fieldset {
      @include themed($themes) {
        border-color: t("text-color-primary") !important;
      }
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: $primary;
    font-size: 1.6rem;
  }
}

.base-Popper-root {
  .MuiPaper-root {
    @include themed($themes) {
      background-color: t("pagesBackgroundColor");
      color: t("text-color-primary") !important;
    }

    .MuiPickersToolbar-root {
      span {
        @include themed($themes) {
          color: t("text-color-primary") !important;
        }
      }
    }
    //content
    .MuiPickersLayout-contentWrapper {
      .MuiPickersCalendarHeader-root {
        .MuiButtonBase-root {
          @include themed($themes) {
            color: t("text-color-primary") !important;
          }
        }
      }

      .MuiPickersFadeTransitionGroup-root {
        font-size: 1.4rem;

        .MuiDayCalendar-root {
          .MuiDayCalendar-header {
            .MuiTypography-root {
              @include themed($themes) {
                color: t("text-color-primary") !important;
              }
              font-size: 1.4rem;
            }
          }

          .MuiButtonBase-root {
            @include themed($themes) {
              color: t("text-color-primary");
            }
            font-size: 1.4rem !important;
            &.Mui-selected {
              background-color: $primary;
            }

            &.Mui-disabled {
              color: #ababab99;
            }
          }
        }

        .MuiPickersMonth-monthButton {
          font-size: 1.4rem !important;
          &.Mui-selected {
            background-color: $primary;
          }

          &.Mui-disabled {
            color: #ababab99;
          }
        }

        .MuiPickersYear-yearButton {
          font-size: 1.4rem !important;
          &.Mui-selected {
            background-color: $primary;
          }

          &.Mui-disabled {
            color: #ababab99;
          }
        }
      }
    }
    //footer
    .MuiDialogActions-root {
      .MuiButtonBase-root {
        font-size: 1.4rem;
        color: $primary;
      }
    }
  }

  .MuiTypography-root {
    font-size: 1.4rem;
  }
}
