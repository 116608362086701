.OTArates {
  display: flex;
  justify-content: center;
  width: $full-width;

  .progressBar {
    margin-right: 4rem;

    .MuiCircularProgress-root {
      @include themed($themes) {
        box-shadow: inset 0 0 0 0.5/4.4 * 10rem t("border-color");
      }
      border-radius: 50%;
      width: 10rem !important;
      height: 10rem !important;
    }
  }

  .total {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
