.Title {
  @include themed($themes) {
    color: t("text-color-primary");
  }

  h1 {
    text-align: center;
    font-size: 3.2rem;
    font-weight: 525;
    line-height: 140%;
  }

  h3 {
    text-align: left;
    font-size: 2rem;
    font-weight: 500;
    line-height: 140%;
  }
}
