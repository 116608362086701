.Header {
  @include themed($themes) {
    background: t("component-background-color");
  }
  height: 7.5rem;
  width: $full-width;
  border-bottom: 0.1rem solid $primary;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 2;

  .loader {
    top: 0;
    position: sticky;
    z-index: 10;

    .MuiLinearProgress-root {
      @include themed($themes) {
        background-color: t("component-background-color") !important;
      }

      .MuiLinearProgress-bar {
        background-color: $primary;
      }
    }
  }

  .minusMargin {
    margin-top: -0.4rem;
  }

  .headerContainer {
    display: flex;
    justify-content: space-between;

    .menu {
      display: flex;
      align-items: center;
      padding: 2rem;

      svg {
        cursor: pointer;
        font-size: 2.5rem;
        @include themed($themes) {
          color: t("text-color-primary");
        }
      }
    }

    .account {
      display: flex;
      align-items: center;
      padding: 2rem;

      .MuiSvgIcon-root {
        @include themed($themes) {
          fill: t("text-color-secondary");
        }
        cursor: pointer;
        font-size: 3.5rem;
      }
    }
  }
}
