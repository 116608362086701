.Login {
  @include themed($themes) {
    background: t("background-color");
  }
  background: black;
  width: $full-view-width;
  min-height: $full-view-height;
  display: flex;
  align-items: center;
  flex-direction: column;

  .authentificationContent {
    @include themed($themes) {
      background: t("component-background-color");
      border: t("paper-border");
    }
    display: flex;
    flex-direction: column;
    margin-top: 15rem;
    width: $full-width;
    max-width: 50rem;
    padding: 4rem;
    border-radius: $border-radius-m;

    .loginHeader {
      @include themed($themes) {
        color: t("text-color-primary");
      }
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;

      .form {
        width: $full-width;
        margin-top: 1rem;

        .inputs {
          display: flex;
          flex-direction: column;

          .MuiFormControl-root {
            margin: 1.6rem 0 0.8rem;

            .MuiInputBase-root {
              background-color: $white;
            }
          }
        }
      }

      .SpinnerLoader {
        margin-top: 2rem;
      }
    }
  }
}
