//https://itnext.io/structuring-your-sass-projects-c8d41fa55ed4

/*Typo*/
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

/* abstract */
@import "abstracts/variables";
@import "abstracts/mixins";

//base

/* themes */
@import "theme/theme-config";

/* components */
// -> atoms
@import "components/atoms/SwitchDarkMode/SwitchDarkMode";
@import "components/atoms/StyledButton/StyledButton";
@import "components/atoms/StyledPaper/StyledPaper";
@import "components/atoms/StyledChip/StyledChip";
@import "components/atoms/SpinnerLoader/SpinnerLoader";
@import "components/atoms/Title/Title";
@import "components/atoms/Text/Text";
@import "components/atoms/InputText/InputText";
@import "components/atoms/InputAutocomplete/InputAutocomplete";
@import "components/atoms/InputAutocomplete/InputAutocomplete";
@import "components/atoms/InputNumber/InputNumber";
@import "components/atoms/InputSwitch/InputSwitch";
@import "components/atoms/InputFile/InputFile";
@import "components/atoms/InputRadio/InputRadio";
@import "components/atoms/InputDate/InputDate";
@import "components/atoms/LinearProgressWithLabel/LinearProgressWithLabel";

// -> molecules
@import "components/molecules/ListItem/ListItem";
@import "components/molecules/HeaderMenu/HeaderMenu";
@import "components/molecules/DataDisplay/DataDisplay";
@import "components/molecules/PromoCodeDisplay/PromoCodeDisplay";
@import "components/molecules/FirmwareFromDisplay/FirmwareFromDisplay";
@import "components/molecules/ConfirmationModal/ConfirmationModal";
@import "components/molecules/FirmwareWithoutFeatureModal/FirmwareWithoutFeatureModal";
@import "components/molecules/OTArates/OTArates";

// -> organisme
@import "components/organisms/GenericTable/GenericTable";
@import "components/organisms/FeatureDataDisplayAndEdit/FeatureDataDisplayAndEdit";
@import "components/organisms/TrackerConfigTable/TrackerConfigTable";
@import "components/organisms/OperatorNetworkDataDisplayAndEdit/OperatorNetworkDataDisplayAndEdit";

// -> pages
@import "components/pages/ErrorPage/ErrorPage";
@import "components/pages/Login/Login";
@import "components/pages/Overview/Overview";
@import "components/pages/Retailers/Retailers/Retailers";
@import "components/pages/Account/Account";
@import "components/pages/Retailers/Retailers/ManageRetailer";
@import "components/pages/Retailers/Groups/ManageGroup";
@import "components/pages/Retailers/Retailers/ManageRetailer";
@import "components/pages/Retailers/StoreLocator/StoreLocator";
@import "components/pages/PromoCodes/Rules/ManageRule";
@import "components/pages/PromoCodes/Affiliates/ManageAffiliate";
@import "components/pages/Software/OTACamapaign/ManageOTACampain";
@import "components/pages/Software/Frimware/ManageFirmware";
@import "components/pages/Software/Hardware/ManageHardware";
@import "components/pages/OperatorNetwork/ManageOperatorNetwork";
@import "components/pages/QualityCheck/ActivateSims/ActivateSims";
@import "components/pages/QualityCheck/AddTrackers/AddTrackers";
@import "components/pages/QualityCheck/SendCommands/SendCommands";
@import "components/pages/OrderB2B/ManageOrderB2B";
@import "components/pages/Users/ManageUsers";

// -> templates
@import "components/templates/NavBar/NavBar";
@import "components/templates/Header/Header";
@import "components/templates/PageTemplate/PageTemplate";
/* layout */

/* global */

:root {
  font-size: 0.625em; /* fallback IE8+ */
  font-size: calc(1em * 0.625);
}

* {
  box-sizing: border-box;
}

body {
  font-family: "Lato";
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-size: 1.6rem;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}

.App {
  @include themed($themes) {
    background: t("background-color");
  }
  width: $full-view-width;
  min-height: $full-view-height;
  display: flex;
  justify-content: center;

  .MuiDrawer-paper {
    background-color: transparent;
  }

  .appContent {
    max-width: $full-view-width;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  }

  .navBarOpen {
    margin-left: 0;
    width: calc($navbar-width - $full-width);
    margin-left: $navbar-width;
    transition: margin 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  }

  .fullWidth {
    width: $full-width;
  }
}

// PopOver and Modal

.base-Popper-root {
  .MuiPaper-root {
    @include themed($themes) {
      background: t("component-background-color");
      color: t("text-color-primary");

      .MuiList-root {
        .MuiButtonBase-root {
          font-size: 1.6rem;

          .MuiListItemIcon-root {
            color: inherit;

            .MuiSvgIcon-root {
              font-size: 2rem;
            }
          }
        }
      }
    }

    //gestion des colomnes
    .MuiDataGrid-panelWrapper {
      .MuiDataGrid-columnsManagementHeader {
        .MuiInputBase-root {
          color: inherit;
          font-size: 1.6rem;

          &::before {
            border-color: inherit;
          }

          .MuiSvgIcon-root {
            color: inherit;
          }

          .MuiInputAdornment-root {
            color: inherit;
          }

          .MuiOutlinedInput-notchedOutline {
            border-color: $primary;
          }
        }

        .Mui-focused {
          .MuiOutlinedInput-notchedOutline {
            @include themed($themes) {
              border-color: t("text-color-primary");
            }
          }
        }
      }

      .MuiDataGrid-columnsManagement {
        .MuiFormControlLabel-root {
          .MuiButtonBase-root {
            color: inherit;
            font-size: 1.6rem;
          }
          .Mui-checked {
            color: $primary;
          }
          .MuiTypography-root {
            font-size: 1.6rem;
          }
        }
      }

      .MuiDataGrid-columnsManagementFooter {
        @include themed($themes) {
          border-color: t("text-color-primary");
        }
        .MuiFormControlLabel-root {
          .MuiButtonBase-root {
            color: inherit;
            font-size: 1.6rem;
          }
          .Mui-checked {
            color: $primary;
          }
          .MuiTypography-root {
            font-size: 1.6rem;
          }
        }

        .MuiButtonBase-root {
          color: $primary;
          font-size: 1.2rem;

          &.Mui-disabled {
            color: rgba(0, 0, 0, 0.26);
          }
        }
      }
    }

    //popover de base
    .MuiButtonBase-root {
      .MuiTypography-root {
        font-size: 1.6rem;
      }

      .MuiListItemIcon-root {
        color: inherit;

        .MuiSvgIcon-root {
          font-size: 2rem;
        }
      }
    }

    //popover des filtres
    .MuiDataGrid-filterForm {
      .MuiButtonBase-root {
        color: inherit;
      }

      .MuiFormLabel-root {
        color: inherit;
        font-size: 1.6rem;
      }

      .MuiInputBase-root {
        color: inherit;
        font-size: 1.6rem;

        &::before {
          border-color: inherit;
        }
        .MuiSvgIcon-root {
          color: inherit;
        }
      }

      .MuiInput-root {
        &::after {
          border-color: $primary;
        }
      }
    }
  }

  .MuiTooltip-tooltip {
    font-size: 1.2rem;
  }
}

.MuiPopover-root {
  .MuiPaper-root {
    @include themed($themes) {
      background: t("component-background-color");
      color: t("text-color-primary");
    }

    .MuiButtonBase-root {
      font-size: 1.6rem;
    }
  }
}
