.NavBar {
  background: $primary-gradient;
  width: $navbar-width;
  height: $full-height;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .logo {
    padding: 2rem 3.5rem;
    height: 7.5rem;
    width: $full-width;
    display: flex;
    justify-content: center;
    @include themed($themes) {
      border-bottom: 0.1rem solid t("component-background-color");
    }

    img {
      width: 100%;
      max-width: 100%;
    }
  }

  .NavList {
    width: $full-width;
    margin-top: 5rem;
    color: $white !important;
    overflow: scroll;
    padding: 0 1rem;

    .MuiListSubheader-root {
      font-size: 1.6rem;
      background-color: transparent;
      font-weight: 700;
      color: $white !important;
    }
  }
}
