.SwitchDarkMode {
  display: flex;
  align-items: center;
  margin-right: 2rem;
  cursor: pointer;

  .MuiSwitch-switchBase {
    @include themed($themes) {
      background-color: t("background-color") !important;
      border: 0.1rem solid t("text-color-secondary");
    }
    width: 2.75rem;
    height: 2.75rem;
    top: 0.4rem;
    left: 0.7rem;

    .icon {
      div {
        display: flex;
        align-items: center;
      }
    }
  }

  .MuiSwitch-track {
    @include themed($themes) {
      background-color: t("text-color-secondary") !important;
    }
    opacity: 1;
  }
}
