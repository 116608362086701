.InputFile {
  width: $full-width;

  .MuiFormControl-root {
    width: $full-width;
    .MuiFormLabel-root {
      @include themed($themes) {
        color: t("text-color-primary");
      }
      font-size: 1.6rem;

      &.Mui-disabled {
        @include themed($themes) {
          color: t("disabled-color");
        }
      }
    }

    .MuiInputBase-root {
      @include themed($themes) {
        background-color: t("background-color") !important;
      }
      font-size: 1.2rem;

      &.Mui-disabled {
        @include themed($themes) {
          border-color: t("disabled-color");
        }
        .MuiOutlinedInput-notchedOutline {
          @include themed($themes) {
            border-color: t("disabled-color") !important;
          }
        }
      }

      .MuiInputBase-input {
        @include themed($themes) {
          color: t("text-color-primary");
          -webkit-box-shadow: 0 0 0 3rem t("background-color") inset !important;
          -webkit-text-fill-color: t("text-color-primary");
        }
        // padding: 1.85rem 1.6rem;
        font-size: 1.6rem;
      }

      svg {
        @include themed($themes) {
          color: t("text-color-primary");
        }
        font-size: 2rem;
      }

      .MuiInputAdornment-root {
        @include themed($themes) {
          color: t("text-color-primary");
        }
      }
      .MuiTypography-root {
        font-size: 1.2rem;
      }
    }

    .MuiOutlinedInput-root {
      @include themed($themes) {
        color: t("text-color-primary");
      }
      fieldset {
        border-color: $primary !important;
      }

      &:hover fieldset {
        @include themed($themes) {
          border-color: t("text-color-primary") !important;
        }
      }

      &.Mui-focused fieldset {
        @include themed($themes) {
          border-color: t("text-color-primary") !important;
        }
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: $primary;
      font-size: 1.6rem;
    }
  }
}
