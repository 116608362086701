.ConfirmationModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 50rem;
  width: $full-width;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem;

  .Title {
    h3 {
      margin-top: 2rem;
      text-align: center;
    }
  }

  .Text {
    margin-top: 3rem;
  }

  .error {
    font-style: italic;
  }

  .buttonsBox {
    display: flex;
    justify-content: space-around;
    width: $full-width;
    margin-top: 3rem;

    .cancel {
      margin-right: 2rem;

      .MuiButton-root {
        background: red;
      }
    }
  }
}
