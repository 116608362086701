.ListItem {
  .MuiListItemButton-root {
    border-radius: $border-radius-m;

    .MuiListItemIcon-root {
      min-width: 4rem;

      .MuiSvgIcon-root {
        font-size: 2rem;
        fill: $white;
      }
    }

    .MuiListItemText-root {
      .MuiTypography-root {
        font-size: 1.6rem !important;
      }
    }
  }
}
