.FirmwareFromDisplay {
  .item {
    display: flex;
    flex-direction: row;
    align-items: center;

    .Text {
      .MuiTypography-root {
        font-size: 1.6rem;
      }
    }
    .Title {
      .MuiTypography-root {
        font-size: 1.8rem;
      }
      margin-bottom: 1rem;
    }

    svg {
      font-size: 2rem;
      margin-right: 3rem;
    }
  }

  .item:not(:first-child) {
    justify-content: center;
  }
}
