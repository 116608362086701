.LinearProgressWithLabel {
  display: flex;
  align-items: center;
  height: $full-height;

  .displayBar {
    width: $full-width;
    margin-right: 3rem;

    .MuiLinearProgress-barColorSecondary {
      background-color: $primary;
    }
  }

  .displayValue {
    .MuiTypography-root {
      @include themed($themes) {
        color: t("text-color-secondary");
      }
      font-size: 1.4rem;
    }
  }
}
