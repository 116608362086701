.StyledButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .MuiButtonBase-root {
    background: $primary-gradient;
    border-radius: 4rem;
    width: 100%;
    max-width: 26rem;
    height: 4.5rem;
    text-transform: none;
    box-shadow: none;
    font-size: 1.6rem;
  }
}
