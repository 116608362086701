.ErrorPage {
  @include themed($themes) {
    background: t("background-color");
  }
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  svg {
    width: 40rem;
    height: 40rem;
  }

  .StyledButton {
    margin-top: 3rem;
  }
}
