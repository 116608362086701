.PageTemplate {
  width: $full-width;
  padding: 5rem 10rem;

  .titleAndButton {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem;

    .alignButtons {
      display: flex;

      .StyledButton {
        width: unset;
        margin-left: 2rem;

        svg {
          margin-right: 1rem;
          font-size: 2rem;
        }
      }

      .cancel {
        .MuiButton-root {
          background: red;
        }
      }
    }
  }

  .closingCross {
    @include themed($themes) {
      color: t("text-color-primary");
    }
    display: flex;
    align-items: center;

    svg {
      font-size: 3rem;
      cursor: pointer;
    }
  }

  .StyledPaper {
    margin-top: 2rem;
    padding: 3rem;

    .buttons {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      width: $full-width;
      padding-top: 3rem;

      .StyledButton {
        max-width: 15rem;
        margin-left: 2rem;
      }
    }
  }
}
