.Text {
  text-align: center;

  .primary {
    @include themed($themes) {
      color: t("text-color-primary");
    }
  }

  .secondary {
    @include themed($themes) {
      color: t("text-color-secondary");
    }
  }

  .tercery {
    @include themed($themes) {
      color: t("text-color-tercery");
    }
  }

  p {
    text-align: start;
    font-size: 1.6rem;
    font-weight: 300;
    line-height: 140%;
  }

  span {
    text-align: start;
    font-size: 1.6rem;
    font-weight: 300;
    line-height: 140%;
  }
}
