.OperatorNetworkDataDisplayAndEdit {
  width: $full-width;

  .operatorNetworkGrid {
    .operatorNetworkData {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      padding-left: 0;
      margin-left: 2.4rem;

      @include themed($themes) {
        border-bottom: 0.1rem solid t("text-color-tercery");
      }

      .rowNameData {
        display: flex;
        padding-top: 0;
        align-items: center;
        padding-left: 2.4rem;
      }

      .data {
        padding-top: 0;
        padding-left: 2.4rem;
        @include themed($themes) {
          color: t("text-color-tercery");
        }
        justify-content: center;
        align-items: center;

        .StyledButton {
          .MuiButtonBase-root {
            font-size: 1.2rem !important;
          }
        }

        .InputSwitch {
          width: unset;
        }
      }
    }

    .operatorNetworkButtons {
      padding-left: 0;
      margin-left: 2.4rem;
      .button {
        padding-top: 1.5rem;
        justify-content: center;

        .StyledButton {
          .MuiButtonBase-root {
            font-size: 1.2rem !important;
          }
        }
      }
    }
  }
}
