.Account {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  border-top: 0.1rem solid $primary;

  .card {
    margin-top: 3rem;

    .inputs {
      display: flex;
      flex-direction: column;

      .subtitle {
        margin-top: 1rem;
      }

      .informations {
        @include themed($themes) {
          color: t("text-color-primary");
        }
        font-size: 1.6rem;
        margin-top: 1rem;
      }

      .alignInput {
        margin: 1.6rem 0rem;
      }
    }
  }
}
