.DataDisplay {
  .item {
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      font-size: 2rem;
      margin-right: 3rem;
    }
  }
}
