.PromoCodeDisplay {
  .item {
    display: flex;
    flex-direction: row;
    align-items: center;

    .Text {
      .MuiTypography-root {
        font-size: 1.6rem;
      }
    }
    .Title {
      .MuiTypography-root {
        font-size: 1.8rem;
      }
      margin-bottom: 1rem;
    }

    svg {
      font-size: 2rem;
      margin-right: 3rem;
    }
  }

  .gridAction {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .StyledButton {
      width: unset;
    }
  }

  .cancel {
    .MuiButton-root {
      background: red;
    }
  }
}
